/* Variables */
:root {
  --main-color: #2d2d2d;
  --text-color: #333;
  --background-color: #f0f0f0;
  --box-shadow-color: rgba(0, 0, 0, 0.5);
  --hover-color: #543;
}

/* General Styles */
body {
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
}

/* Navbar */
.navbarlogo {
  width: 22%;
  border-radius: 50px;
}
.header {
  margin: 1.5vw;
  font-size: 3vw;
  color: black;
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 2vw;
}

.navbar-links a {
  font-size: 1.3vw;
  text-decoration: none;
  color: var(--text-color);
}

.navbar-links a:hover {
  color: darkgray;
}
.navbar-links li {
  margin-left: 3vw;
}

.navbar .ml-auto {
  margin-left: auto;
}

/* About */
.h1,
.header-3 {
  margin: 5.5vw;
  font-size: 3vw;
}

.body-text {
  font-size: 1.2vw;
  margin: 5.5vw;
}

.custom-button {
  width: 150px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 5vw;
  font-size: 17px;
}

.custom-button:hover {
  background-color: darkgray;
  color: #fff;
}

.about-container {
  display: flex;
  justify-content: space-between;
}

.about-image {
  display: flex;
  max-width: 60%;
  margin: 5.5vw;
  margin-left: auto;
  border-radius: 10vw;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
}

/* Work */
.header-2 {
  font-size: 40px;
  text-align: center;
}

.custom-card {
  transition: box-shadow 0.4s, transform 0.4s;
  box-shadow: 2% 2% 4% var(--box-shadow-color);
  flex-basis: calc(25% - 1.6vw);
  margin: 0.8vw;
}
.custom-card-container {
  padding: 0 1vw;
  justify-content: space-between;
}

.custom-card:hover {
  box-shadow: 0 0 2% 2% var(--box-shadow-color);
  transform: scale(1.03);
}

.custom-card .custom-card-image {
  width: 100%;
  padding-top: 75%;
  position: relative;
}

.custom-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-card-icon-2 {
  width: 12%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card-icon-2 img {
  width: 35px;
  height: 35px;
}

.custom-card-icons {
  display: flex;
  margin-bottom: 15%;
}

.card-title {
  font-weight: bold;
  font-size: 20px;
}

.card-text {
  font-size: 18px;
  margin-bottom: 10px;
}
.custom-card-text {
  margin-top: 7.5px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 100px;
}
.custom-card-body {
  margin-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
}
.icon-row {
  display: flex;
  justify-content: space-around;
}

.icon {
  width: 4.5%;
  margin-bottom: 3%;
  margin-top: 3%;
}

.icons {
  margin-top: 5vw;
}

.icon:last-child {
  margin-right: 0;
}

.icons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 20px;
}
.icon-link img {
  height: 40px;
  width: 40px;
  border-radius: 12px;
}

/* Login Container */
.login-container {
  background-image: url('https://rampagida.com.tr/resim/contact.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  height: 28vw;
}

.left-content {
  flex: 1;
}

.login-form {
  max-width: 28%;
  width: 100%;
}

.login-form input {
  background-color: white;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  width: 24vw;
  height: 3vw;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 10px;
}
.loginform-p {
  font-family: Arial, sans-serif;

  color: black;
}
.submit-button {
  width: 150px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}
.submit-button:hover {
  background-color: darkgray;
  color: #fff;
}
@media (max-width: 1024px) {
  .login-container {
    height: 50vw;
    flex-direction: column;
    text-align: center;
  }

  .login-form {
    margin-top: 10px;
    max-width: none;
  }
  .icon-link img {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }
  .login-form input {
    font-size: 20px;
    width: 60vw;
    height: 50px;
    margin-bottom: 10px;
  }
  .header4 {
    font-size: 55px;
    margin-bottom: 30px;
  }
  .loginform-p {
    font-size: 30px;
  }
  .submit-button {
    width: 25vw;
    height: 55px;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .login-container {
    height: 65vw;
    text-align: center;
  }

  .login-form input {
    width: 65vw;
    height: 45px;
    margin-bottom: 10px;
  }
  .loginform-p {
    font-size: 25px;
  }
  .icon-link img {
    height: 50px;
    width: 50px;
    border-radius: 12px;
  }
  .submit-button {
    width: 25vw;
    height: 45px;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 568px) {
  .login-container {
    height: 98vw;
    text-align: center;
  }

  .login-form input {
    font-size: 14px;
    width: 70vw;
    height: 35px;
    margin-bottom: 5px;
  }
  .loginform-p {
    font-size: 15.5px;
  }
  .icon-link img {
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }
  .submit-button {
    width: 35vw;
    height: 33px;
    font-size: 15px;
    margin-bottom: 10px;
  }
}

/* About Page */
@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.custom-page-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 20px;
}

.custom-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5);
  padding: 20px;
}

.custom-image-container {
  width: 50%;
}

.custom-image {
  width: 60%;
  height: auto;
  border-radius: 25vw;
}

.custom-paragraph {
  font-size: 25px;
  margin-left: 20px;
  line-height: 1.9;
  max-width: 50%;
  opacity: 0;
}

.custom-about,
.custom-image {
  animation-duration: 2s;
}

.custom-image-container {
  animation: slideInFromTop 0.5s ease-out forwards;
}

.custom-paragraph {
  animation: slideInFromRight 0.5s ease-out forwards;
  animation-delay: 1s;
}

/* Contact Form */
.registration-container {
  align-items: center;
  text-align: center;
}

.small-registration-form {
  display: flex;
  flex-direction: column;
  max-width: 25vw;
  margin: auto;
}

.small-form-group {
  margin-top: 6px;
  margin-bottom: 5px;
}

.small-form-group input {
  padding: 12px;
  font-size: 16px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  padding-left: 10px;
}

.contact-button {
  width: 150px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 auto;
}
.contact-button:hover {
  background-color: darkgray;
  color: #fff;
}
.header-5 {
  text-align: center;
  margin-bottom: 30px;
}

.header-7 {
  margin-top: 6vw;
  margin-bottom: 2.5vw;
  font-size: 3vw;
  text-align: center;
}
p {
  font-size: 20px;
  margin-bottom: 30px;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .about-container {
    margin: 65px 0;
    flex-direction: column-reverse;
  }

  .about-image {
    max-width: 65%;
    margin: 0 auto;
  }

  .navbar-links a,
  .h1 {
    font-size: 3.8vw;
    text-align: center;
  }

  .custom-button {
    display: block;
    margin: 0 auto;
    width: 24vw;
    height: 6.5vw;
    font-size: 3vw;
    margin-bottom: 5vw;
  }

  .header {
    font-size: 5.5vw;
  }

  .header-2 {
    font-size: 8.5vw;
  }

  .header-3 {
    font-size: 9vw;
    text-align: center;
  }
  .body-text {
    text-align: center;
    font-size: 3.2vw;
  }

  .header-5 {
    font-size: 5.5vw;
  }

  .header-7 {
    margin-bottom: 20px;
    font-size: 10vw;
    text-align: center;
  }

  .icon {
    margin-top: 0.5vw;
    margin-bottom: 9.5vw;
    width: 12%;
  }
  .icon-row {
    width: 96vw;
  }
  .custom-card {
    font-size: 3.5vw;
  }
  .custom-card {
    flex-basis: calc(50% - 2vw);
    margin: 1vw;
  }

  .custom-card-container {
    padding: 0.2vw;
    margin-left: 22px;
  }
  .custom-card .custom-card-image {
    width: 95%;
  }

  .icons {
    margin-bottom: 2vw;
  }

  .custom-card-icon-2 {
    width: 12%;
  }

  .custom-card-icons {
    margin-top: 8%;
    margin-bottom: 25%;
  }

  .custom-card-icon-2 img {
    width: 40px;
    height: 40px;
  }

  .card-title {
    margin-bottom: 20px;
    font-size: 4vw;
  }

  .card-text {
    margin-top: 2vw;
    font-size: 3vw;
  }
  .custom-card-text {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    padding-left: 2px;
    padding-right: 10px;
  }
  .custom-card-body {
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 20px;
  }
  .custom-page-container {
    margin-top: 30px;
    padding: 35px;
  }

  .custom-text-container {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .custom-about {
    text-align: center;
  }

  .custom-image-container {
    margin-top: 6vw;
    width: 75%;
  }

  .custom-image {
    width: 100%;
    height: auto;
  }

  .custom-paragraph {
    text-align: center;
    font-size: 35px;
    margin-left: 0;
    max-width: 100%;
  }
  .registration-container {
    margin-top: 15vw;
  }

  .small-registration-form {
    max-width: 60vw;
  }

  .small-form-group {
    margin-bottom: 10px;
  }

  .small-form-group input {
    padding: 20px;
    font-size: 20px;
    width: 100%;
    height: 5.5vw;
  }
  .contact-button {
    margin-top: 10px;
    width: 25vw;
    height: 6vw;
    font-size: 25px;
  }

  p {
    font-size: 3vw;
  }
  .custom-image {
    border-radius: 35vw;
  }
  .about-image{
    border-radius: 45vw;

  }
}

@media only screen and (max-width: 768px) {
  .navbar-links a,
  .h1,
  .custom-button {
    text-align: center;
  }

  .custom-button {
    width: 24vw;
    height: 6.5vw;
    font-size: 3vw;
  }

  .about-container {
    margin: 65px 0;
    flex-direction: column-reverse;
  }

  .header {
    font-size: 5.5vw;
  }

  .about-image {
    max-width: 65%;
    margin: 0 auto;
    margin-bottom: 3vw;
    border-radius: 35vw;
  }

  .header-2 {
    font-size: 7.5vw;
  }

  .header-3 {
    font-size: 8.5vw;
    text-align: center;
  }

  .header-5 {
    font-size: 4.5vw;
  }

  .header-7 {
    margin-bottom: 20px;
    font-size: 9vw;
    text-align: center;
  }
  .body-text {
    font-size: 2.8vw;
  }

  .new-about-page-container,
  .new-paragraph {
    margin-top: 8%;
  }

  .icon {
    margin-bottom: 4vw;
  }
  .icon-row {
    margin-bottom: 15vw;
    width: 95vw;
  }
  .custom-card {
    flex-basis: calc(50% - 2vw);
    margin: 1vw;
  }

  .custom-card-container {
    padding: 0.2vw;
    margin-left: 20px;
    justify-content: space-around;
  }
  .custom-card .custom-card-image {
    width: 95%;
  }
  .custom-card-icon-2 {
    width: 14%;
  }

  .custom-card-icons {
    margin-bottom: 25%;
  }

  .custom-card-icon-2 img {
    width: 40px;
    height: 35px;
  }

  .card-text {
    font-size: 22px;
  }

  .card-title {
    margin-bottom: 15px;
    font-size: 28px;
  }
  .custom-card-text {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    padding-left: 0;
    padding-right: 10px;
  }
  .custom-card-body {
    margin-top: 25px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .header4 {
    margin-top: 5px;
    font-size: 45px;
  }
  .custom-page-container {
    margin-top: 30px;
    padding: 32px;
  }

  .custom-text-container {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .custom-about {
    text-align: center;
  }

  .custom-image-container {
    width: 75%;
  }

  .custom-image {
    width: 100%;
    height: auto;
  }

  .custom-paragraph {
    padding: 5vw;
    text-align: center;
    font-size: 25px;
    margin-left: 0 auto;
    max-width: 100%;
  }
  .registration-container {
    margin-top: 15vw;
  }

  .small-registration-form {
    max-width: 50vw;
  }

  .small-form-group {
    margin-bottom: 7px;
  }

  .small-form-group input {
    font-size: 12px;
    width: 100%;
    font-size: 2.5vw;
    padding-left: 12px;
  }
  .contact-button {
    margin-top: 10px;
    font-size: 15.5px;
  }
  .custom-image {
    border-radius: 40vw;
  }
}

@media only screen and (max-width: 568px) {
  .about-container {
    margin: 40px 0;
    flex-direction: column-reverse;
  }

  .about-image {
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 1vw;
  }

  .custom-button {
    width: 35vw;
    height: 9vw;
    font-size: 4vw;
  }

  .header-3 {
    font-size: 9vw;
    margin-top: 5vw;
  }

  .header-5 {
    font-size: 5.5vw;
  }

  .header-7 {
    margin-bottom: 20px;
    font-size: 10vw;
    text-align: center;
  }

  .header {
    font-size: 6.2vw;
    margin-bottom: 3vw;
  }

  .header-2 {
    font-size: 9.2vw;
  }

  .body-text {
    font-size: 4.2vw;
    margin-bottom: 10vw;
  }

  .navbar-links a {
    font-size: 4vw;
  }
  .custom-card {
    flex-basis: calc(50% - 2vw);
    margin: 1vw;
  }

  .custom-card-container {
    padding: 1vw;
    margin-left: 7.5px;
    justify-content: space-around;
  }
  .custom-card .custom-card-image {
    width: 95%;
  }
  .card-title {
    font-weight: bold;
    font-size: 14.5px;
    margin-bottom: 8px;
  }

  .custom-card-icon-2 {
    width: 14%;
  }

  .custom-card-icons {
    margin-bottom: 32%;
  }

  .custom-card-icon-2 img {
    width: 18px;
    height: 18px;
  }

  .card-text {
    font-size: 12px;
  }
  .custom-card-text {
    margin-bottom: 13px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .custom-card-body {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 9px;
  }
  .header4 {
    font-size: 28px;
  }

  .custom-page-container {
    margin-top: 30px;
    padding: 15px;
  }

  .custom-text-container {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .custom-about {
    text-align: center;
  }

  .custom-image-container {
    width: 85%;
  }

  .custom-image {
    width: 100%;
    height: auto;
  }

  .custom-paragraph {
    text-align: center;
    font-size: 15px;
    margin-left: 0 auto;
    max-width: 100%;
  }

  .icon-row {
    margin-right: 0.1vw;
    width: 94vw;
  }
  .registration-container {
    margin-top: 30vw;
  }

  .small-registration-form {
    max-width: 80vw;
  }

  .small-form-group {
    margin-bottom: 7px;
  }

  .small-form-group input {
    font-size: 12px;
    width: 100%;
  }

  p {
    margin-bottom: 8vw;
    font-size: 4vw;
  }
  .contact-button {
    margin-top: 8px;
    width: 32vw;
    height: 9vw;
    font-size: 12px;
  }
  .about-image{
    border-radius: 45vw;

  }
}
